import logo from './Eclipse.jpg';
import './App.css';
import WhyUseFHIR from './content/WhyUseFHIR';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} class="frame" alt="logo" />
        <h1>Cooking with FHIR</h1>
        <WhyUseFHIR/>
      </header>
    </div>
  );
}

export default App;
