
export default function WhyUseFHIR() {


    return (

        <div>

            <div><p><em>This is a placholder page with some draft text. More to come, soon!</em></p></div>
            <div>
            <h2>Why Use FHIR?</h2>

            <p>The FHIR API is enjoying widespread adoption across the
                healthcare industry. It has made possible things that we struggled with
                throughout the 2000s and even 2010s. Here are some reasons why you should
                consider using FHIR for your next health data connectivity project:</p>

            <ul>
                <li>You can install and configure your own FHIR server or use one of
                    several publicly available FHIR servers.</li>
                <li>Creating test data is hard - and clinically meaningful test data
                    even harder! - but there are tools to make this easier in FHIR.</li>
                <li>You can test FHIR operations using simple REST client tools like
                    CURL or Postman. You can use as little or as much testing framework as you
                    need.</li>
                <li>FHIR is flexible and can specialize using profiles, which combine
                    data structure specifications, code and value sets, and server side operations, taking the
                    guesswork out of client development and eliminating the headache of mismatched interfaces.</li>
                <li>FHIR servers can be interrogated as to their capabilities and
                    supported value sets using the CapabilityStatement resource.</li>
                <li>RESTful APIs have proven themselves to be secure, efficient and
                    easy to manage!</li>
            </ul>

            <p>
                For years a number of difficult problems stymied application developers 
                who worked with medical records. The servers and systems for storing and 
                delivering were so large and complex that it was difficult if not impossible 
                to replicated them in a local development environment. Vendors each had 
                their own programming interface with varying features and formats, so 
                applications would need to be built for each server type, tightly coupling 
                them to the particular system a hospital or clinic might be using.
            </p><p>
                Furthermore, we had a data consistency problem. The transition from paper 
                to electronic records was haphazard and fragmented resulting in different 
                data formats, even different locations for that data. Each hospital was 
                trying to replicate their own paper records within the server’s data model. 
                If something didn’t fit, they could simply create custom data fields, 
                changing the data model to fit their standards and practices. This led to 
                a lot of translation work, not only for each medical record server type, 
                but often also each individual site installation.
            </p><p>
                You can see why the conversion of paper records to electronic can result in 
                a lack of standardization, but you would think that going forward everything 
                entered directly into the EMR system at the point of care would at least be 
                consistent. Not quite. The early EMR systems providers were given could be 
                difficult to use and many doctors simply wrote their notes in large text blocks. 
                This, plus the custom fields created in the migration process, left downstream 
                computer systems without a consistent data model and without consistent data 
                entry into that data model. So even if you could run a lightweight version 
                locally, the problem of generating test data and distributing it in the same 
                way it appears in the real world became a rather intractable problem.
            </p><p>
                Enter the FHIR standard, finally giving us a consistent data model, a RESTful 
                API, and an open source reference implementation that can be installed on a 
                linux server. With the FHIR data model, creating synthetic patient data became 
                easier, and now there are tools which automate data creation, and sample data 
                sets ready to load and use. Application queries can be developed and tested 
                using a number of REST API client tools like CURL or Postman. SDKs are readily 
                available in your favorite programming languages. A fully developed and tested 
                application can now be compatible with any number of EMR vendors without needing 
                access to any on-site EMR installations.
            </p><p>
                And what of that problem with messy data? It still exists, but the EMR vendor 
                can put a FHIR server in front of it and take care of any site-specific custom 
                data, moving the translation work to one place. Any provider notes will still 
                require some natural language processing (NLP) to ingest, but at least now the 
                client application can build against a standard FHIR spec.
            </p><p>
                You can get started with FHIR development today. You can install and configure your 
                own local FHIR server, populate it with realistic test data, and build client 
                applications against it. You can even modify the configuration to simulate 
                different vendors, customizations, and use cases. The future (of medical record 
                interoperability) is on FHIR!
            </p>
        </div>
        </div>
    );
}
